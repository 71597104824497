@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  // @font-face {
  //   font-family: 'Work Sans';
  //   font-weight: 300 700;
  //   font-display: swap;
  //   src: url('./fonts/WorkSans-VariableFont_wght.ttf') format('ttf');
  // }

  /* work-sans-300 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/work-sans-v18-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/work-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/work-sans-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/work-sans-v18-latin-300.woff') format('woff'), /* Modern Browsers */
        url('../fonts/work-sans-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/work-sans-v18-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-regular - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/work-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/work-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/work-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/work-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/work-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/work-sans-v18-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-500 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/work-sans-v18-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/work-sans-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/work-sans-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/work-sans-v18-latin-500.woff') format('woff'), /* Modern Browsers */
        url('../fonts/work-sans-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/work-sans-v18-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-600 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/work-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/work-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/work-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/work-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
        url('../fonts/work-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/work-sans-v18-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
  }
  /* work-sans-700 - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/work-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/work-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/work-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/work-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
        url('../fonts/work-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/work-sans-v18-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
  }

  html {
    overflow-y: scroll;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    touch-action: none;
    // @apply overflow-y-scroll;
    // @JsonIgnoreProperties
    // foo: bar;
  }

  body {
    @apply h-full antialiased; // transition duration-[5000] ease-in-out delay-1000 bg-sweetAiran dark:bg-laborWorker;
    font-family: 'Work Sans', Helvetica, sans-serif, Arial;
    // overscroll-behavior: none;
    @JsonIgnoreProperties
    foo: bar;
  }

  p {
    @apply text-sm sm:text-base font-normal text-gray-700 dark:text-gray-300 pt-1;
    @JsonIgnoreProperties
    foo: bar;
  }

  h3 {
    @apply text-center font-semibold pt-5 pb-1 tracking-[0.1rem] text-xl text-gray-600 dark:text-gray-200;
    @JsonIgnoreProperties
    foo: bar;
  }

  h4 {
    @apply font-medium pt-5 pb-1 text-xl text-gray-700 dark:text-gray-100;
    @JsonIgnoreProperties
    foo: bar;
  }

  .background-gradient {
    background-size: 400%;
    background-image: linear-gradient(to right, #2C3E50, #000000 25%, #D7E1EC 75%, #F1F1F1);
    transition-duration: 1.5s;
    // animation: bg-animation 4s;
    // animation-delay: 5s;
  }

  @keyframes bg-dark-to-light {
    0% {background-position: left}
    50% {background-position: left}
    75% {background-position: center}
    100% {background-position: right}
  }

  @keyframes bg-light-to-dark {
    0% {background-position: right}
    50% {background-position: right}
    75% {background-position: center}
    100% {background-position: left}
  }

  .header {
    // @apply align-middle z-30 w-[100vw] md:px-4 md:pt-4 md:mx-auto bg-sweetAiran dark:bg-laborWorker md:place-content-center md:fade-bottom;
    @apply align-middle z-30 w-[100vw] md:px-4 md:pt-4 md:mx-auto md:place-content-center md:fade-bottom;
    overflow: hidden;
    position: fixed;
    @JsonIgnoreProperties
    foo: bar;
  }

  .section-title {
    @apply text-xl sm:text-3xl pt-6 md:pt-0 pb-3 md:pb-4 font-light tracking-[0.6rem] sm:tracking-[0.8rem] uppercase text-center dark:text-white;
    @JsonIgnoreProperties
    foo: bar;
  }

  .section {
    @apply mt-[36px] md:mt-0 md:pt-[106px] md:overflow-y-hidden overflow-x-hidden;
    height: auto;
    -webkit-overflow-scrolling: touch;
    @JsonIgnoreProperties
    foo: bar;
  }

  .fade-bottom {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, rgba(0,0,0,0.3) 70%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, rgba(0,0,0,0.3) 70%, transparent 100%);
  }

  .mobile-fade-bottom {
    -webkit-mask-image: linear-gradient(to bottom, black 80%, rgba(0,0,0,0.5) 90%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 80%, rgba(0,0,0,0.5) 90%, transparent 100%);
  }
  ::-webkit-scrollbar {
    width: 1em;
  }

  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      width: 0.5em;
    }
  }

  ::-webkit-scrollbar-track {
    @apply bg-gray-100 dark:bg-gray-200;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100vw;
    margin-block: .5em;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(66,148,238, 0.6);
    border-radius: 100vw;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-healthcareBlue;
    @JsonIgnoreProperties
    foo: bar;
  }

  .nav-menu {
    // @apply pb-10 mt-0 pt-0 align-middle place-items-start flex flex-row justify-between w-full mx-auto max-w-7xl;
    @apply pb-10 mt-0 pt-0 align-middle items-start flex flex-row justify-between w-full mx-auto max-w-7xl;
    .menu-item {
      @apply text-lg leading-none uppercase text-gray-400 dark:text-gray-400 hover:text-healthcareBlue dark:hover:text-healthcareBlue;
      margin: 0 1.5rem;
      cursor: pointer;
      position: relative;
    }

    .activeMenuItem {
      @apply text-healthcareBlue opacity-100;
      text-decoration: none;
      @JsonIgnoreProperties
      foo: bar;
    }

    .underline {
      @apply absolute z-30 w-full left-0 top-[calc(100%_+_4px)] h-[4px] rounded bg-healthcareBlue;
      @JsonIgnoreProperties
      foo: bar;
    }
  }

  .mobile-nav-menu {
    @apply w-full h-full px-1 border-gray-300 dark:border-gray-600 flex flex-row place-items-center justify-between overflow-y-hidden;
    @JsonIgnoreProperties
    foo: bar;

    .menu-item {
      @apply text-lg text-gray-500 dark:text-gray-300 hover:text-healthcareBlue;
      cursor: pointer;
      position: relative;
      @JsonIgnoreProperties
      foo: bar;
    }

    .activeMenuItem {
      @apply text-healthcareBlue dark:text-healthcareBlue opacity-100;
      @JsonIgnoreProperties
      foo: bar;
    }

    .top-underline {
      @apply absolute z-30 w-full left-0 top-[calc(100%_-_3px)] h-[3px] rounded bg-healthcareBlue;
      @JsonIgnoreProperties
      foo: bar;
    }

    .bottom-underline {
      @apply absolute z-30 w-full left-0 bottom-[calc(100%_+_4px)] h-[3px] rounded bg-healthcareBlue;
      @JsonIgnoreProperties
      foo: bar;
    }
  }

  .social-menu-item {
    @apply px-3 items-center cursor-pointer text-gray-400 dark:text-gray-400 font-medium uppercase transition-all hover:text-black focus:text-black dark:hover:text-white dark:focus:text-white;
    @JsonIgnoreProperties
    foo: bar;
  }

  .mobile-hero-social-link {
    @apply py-2 px-3 items-center text-gray-500 dark:text-gray-50 font-medium uppercase transition-all hover:text-healthcareBlue focus:text-healthcareBlue;
    @JsonIgnoreProperties
    foo: bar;
  }

  .logo-image {
    // @apply relative z-20 mx-auto mt-24 mb-4 rounded-full shadow-[3px_3px_5px_10px_rgba(0,0,0,0.3)] w-[250px] h-[250px];
    @apply relative z-20 mx-auto mt-24 mb-4 rounded-full w-[250px] h-[250px];
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    box-shadow: rgba(17, 17, 26, 0.15) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 24px, rgba(17, 17, 26, 0.15) 0px 16px 56px;
    @JsonIgnoreProperties
    foo: bar;
  }

  .logo-image-glow {
    @apply absolute top-0 left-0 w-[250px] h-[250px] rounded-full;
    @JsonIgnoreProperties
    foo: bar;
  }

  @media (max-width: 768px) {
    .logo-image, .logo-image-glow {
      width: 175px;
      height: 175px;
    }
  }

  .carousel-container::-webkit-scrollbar {
    display: none;
  }

  .carousel-container {
    @apply relative;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @JsonIgnoreProperties
    foo: bar;
  }

  .cards-inner-carousel {
    @apply flex flex-row my-auto;
    @JsonIgnoreProperties
    foo: bar;
  }

  .cards-carousel {
    @apply relative p-2 md:p-10 z-10 cursor-grab my-auto;
    @JsonIgnoreProperties
    foo: bar;
    //  bg-gradient-to-r from-[rgba(0,0,0,0.2)] to-[rgba(44,62,80,0.4)];
  }

  .fade-left-right {
    -webkit-mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%);
    mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent 100%);
  }

  .fade-left {
    -webkit-mask-image: linear-gradient(to right, transparent, black 80%,);
    mask-image: linear-gradient(to right, transparent, black 80%);
  }

  .fade-right {
    -webkit-mask-image: linear-gradient(to right, black 80%, transparent 100%);
    mask-image: linear-gradient(to right, black 80%, transparent 100%);
  }

  .nav-carousel {
    @apply z-20 absolute cursor-pointer top-1/2 -translate-y-1/2 text-black dark:text-white opacity-40 dark:opacity-40 py-4 transition duration-500 hover:opacity-90 hover:dark:opacity-90 hover:dark:border-healthcareBlue;
    @JsonIgnoreProperties
    foo: bar;
  }

  .nav-carousel-left {
    @apply left-0;
    @JsonIgnoreProperties
    foo: bar;
  }

  .nav-carousel-right {
    @apply right-0;
    @JsonIgnoreProperties
    foo: bar;
  }

  .card-component {
    @apply flex flex-col md:grow md:items-center bg-gray-50 md:rounded-md md:mx-4 my-4 md:my-0 shadow-md h-full md:min-h-[30rem] md:min-w-[20rem] md:w-[375px] transform transition dark:shadow-[0_0_4px_2px_rgba(255,255,255,0.05),0_0_5px_3px_rgba(255,0,255,0.05),0_0_7px_4px_rgba(0,255,255,0.05)] dark:bg-gray-900;
    @JsonIgnoreProperties
    foo: bar;
  }

  .card-button {
    @apply flex flex-row items-center self-end px-6 md:px-2 py-2 mx-auto mt-1 md:mt-2 align-bottom bg-gray-100 dark:bg-gray-800 md:bg-transparent md:dark:border-2 rounded-md text-sm md:text-base text-white hover:text-white hover:dark:text-white shadow-sm hover:shadow-md dark:hover:shadow-[0_0_2px_1px_rgba(255,255,255,0.5),0_0_4px_2px_rgba(255,255,255,0.3),0_0_8px_4px_rgba(255,255,255,0.2)] transition duration-150 ease-in-out;
    // @apply flex flex-row items-center self-end px-6 md:px-2 py-2 mx-auto mt-1 md:mt-2 align-bottom bg-gray-100 dark:bg-gray-800 md:bg-transparent md:dark:border-2 rounded-md text-sm md:text-base text-white md:dark:hover:bg-gradient-to-r md:dark:hover:from-gray-600 md:dark:hover:to-transparent hover:text-white shadow-sm hover:shadow-md md:dark:hover:shadow-[0_0_2px_1px_rgba(255,255,255,0.45),0_0_4px_2px_rgba(255,255,255,0.2),0_0_8px_4px_rgba(255,255,255,0.1)] transition duration-150 ease-in-out;
    // background: linear-gradient(115deg, transparent 25%, rgba(255,255,255,0.5) 50%, transparent 75%);
    // background-size: 300%;
    // background-position: right;
    // transition: background-position 500ms;
    @JsonIgnoreProperties
    foo: bar;
  }

  // .card-button:hover {
  //   background-position: left;
  // }

  .skill {
    @apply px-2 py-1 mt-0 md:mb-5 mr-1 whitespace-nowrap text-xs font-semibold border-2 rounded-2xl transition-all duration-300;
    @JsonIgnoreProperties
    foo: bar;
  }

  .skill-unselected {
    @apply text-gray-700 bg-transparent dark:text-gray-300 border-slate-300 dark:border-gray-700 dark:bg-gray-800 hover:bg-healthcareBlue dark:hover:bg-healthcareBlue dark:hover:text-white hover:text-white hover:opacity-100;
    @JsonIgnoreProperties
    foo: bar;
  }

  .skill-selected {
    @apply text-white border-healthcareBlue bg-healthcareBlue hover:opacity-[85%] dark:text-white;
    @JsonIgnoreProperties
    foo: bar;
  }

  .expand-skills-button {
    @apply cursor-pointer;
    @JsonIgnoreProperties
    foo: bar;
  }

  .expand-skills-button-not-hovered {
    @apply text-gray-800 dark:text-gray-200;
    @JsonIgnoreProperties
    foo: bar;
  }

  .expand-skills-button-hovered {
    @apply text-healthcareBlue dark:text-healthcareBlue;
    @JsonIgnoreProperties
    foo: bar;
  }

  .mobile-skills-carousel-container::-webkit-scrollbar {
    display: none;
  }

  .mobile-skills-carousel-container {
    @apply relative bg-gray-100 dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700; // my-auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @JsonIgnoreProperties
    foo: bar;
  }

  .mobile-skills-buttons-carousel {
    @apply relative z-10 cursor-grab my-auto mobile-skills-fade-right;
    @JsonIgnoreProperties
    foo: bar;
    //  bg-gradient-to-r from-[rgba(0,0,0,0.2)] to-[rgba(44,62,80,0.4)];
  }

  .mobile-skills-buttons-inner-carousel {
    @apply flex flex-row my-auto py-3;
    @JsonIgnoreProperties
    foo: bar;
  }

  .mobile-skills-fade-right {
    -webkit-mask-image: linear-gradient(to right, black 75%, transparent 95%, transparent 100%);
    mask-image: linear-gradient(to right, black 75%, transparent 95%, transparent 100%);
  }

  form {
    @apply mx-auto w-full;
    @JsonIgnoreProperties
    foo: bar;
  }

  label {
    @apply text-sm md:text-base mb-1 md:mb-3 text-gray-600 dark:text-white;
    @JsonIgnoreProperties
    foo: bar;
  }

  input, textarea {
    @apply w-full p-2 rounded-md focus:shadow-lg outline-none transition-all focus:drop-shadow-lg border-[3px] border-gray-50 dark:border-gray-800 focus:border-healthcareBlue focus:dark:border-healthcareBlue focus:dark:shadow-[0_0_2px_1px_rgba(255,255,255,0.5),0_0_5px_2px_rgba(255,0,255,0.25),0_0_7px_5px_rgba(0,255,255,0.25)];
    @JsonIgnoreProperties
    foo: bar;
  }

  input:focus, textarea:focus {
    @apply bg-amber-50 outline-none;
    @JsonIgnoreProperties
    foo: bar;
  }

  .button {
    @apply bg-healthcareBlue text-gray-100 hover:text-white hover:border-black border-2;
    @JsonIgnoreProperties
    foo: bar;
  }

  .fields {
    @apply mb-4 md:my-5;
    @JsonIgnoreProperties
    foo: bar;
  }

  .gr-icon path {
    stroke: currentColor;
  }

  svg path {
    stroke: currentColor;
  }

  /* setup tooltips */
  .tooltip {
    position: relative;
  }
  .tooltip:before,
  .tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }
  .tooltip:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(66,148,238,.75);
    border-left: 6px solid transparent;
    content: '';
    height: 0;
    top: 20px;
    left: 15px;
    width: 0;
  }
  .tooltip:before {
    @apply text-xs capitalize;
    // @apply bg-healthcareBlue;
    background: rgba(66,148,238,.75);
    border-radius: 3px;
    color: #fff;
    content: attr(data-title);
    // font-size: 14px;
    padding: 6px 10px;
    top: 26px;
    left: -30px;
    white-space: nowrap;
  }

  /* the animations */
  /* fade */
  .tooltip.fade:after,
  .tooltip.fade:before {
    transform: translate3d(0,-10px,0);
    transition: all .15s ease-in-out;
  }
  .tooltip.fade:hover:after,
  .tooltip.fade:hover:before {
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  /* expand */
  .tooltip.expand:before {
    transform: scale3d(.2,.2,1);
    transition: all 0.3s ease-in-out;
    // transition: all .2s ease-in-out;
  }
  .tooltip.expand:after {
    transform: translate3d(0,6px,0);
    transition: all .2s ease-in-out;
    // transition: all .1s ease-in-out;
  }
  .tooltip.expand:hover:before,
  .tooltip.expand:hover:after {
    opacity: 1;
    transform: scale3d(1,1,1);
  }
  .tooltip.expand:hover:after {
    transition: all .2s .1s ease-in-out;
  }

  .drawer-wrapper {
    @apply fixed z-50 w-[100%] h-[calc(100%_-_2rem)] bottom-0 left-0;
    @JsonIgnoreProperties
    foo: bar;
  }

  .drawer {
    @apply h-[calc(100vh_+_400px)] px-[1rem] pt-[1rem] pb-[400px] bg-gray-50 rounded-3xl dark:bg-gray-800 text-gray-800 dark:text-gray-200 border-2 border-gray-400 dark:border-gray-400 shadow-[0px_-13px_-18px_rgba(0,0,0,0.8)];
    @JsonIgnoreProperties
    foo: bar;
  }

  .drawer-background {
    @apply fixed bg-[rgba(0,0,0,0.6)] top-0 left-0 right-0 bottom-0 z-40;
  }
}
